/*
 * Angular Material, material design components
 * see https://material.angular.io
 */

@import '@schaeffler/styles/src/lib/scss/material-theme';

/*
 * TailwindCSS, utility-first css framework
 * see https://tailwindcss.com/docs
 */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'ngx-spinner/animations/ball-scale-multiple.css';
@import 'ngx-spinner/animations/ball-clip-rotate-multiple.css';
@import 'ngx-spinner/animations/timer.css';
@import 'ngx-spinner/animations/ball-scale-ripple-multiple.css';
@import 'ngx-spinner/animations/ball-clip-rotate.css';

@import 'material-symbols';

/* You can add global styles to this file, and also import other style files */

html * {
  font-family: 'Noto Sans';
}

.mat-dialog-container {
  border-radius: 1.75rem !important;
}

.mat-dialog-container-sch-confirmation .mat-dialog-container {
  background-color: #e8e8e8 !important;
  border-radius: 28px !important;
}


.mat-tab-header {
  border: solid 1px #d3d3d3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: unset;
}

.mat-tab-body {
  border: solid 1px #d3d3d3;
  padding: 0.5rem;
}

// .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
//   transform: translateY(-1.59375em) scale(0.95);
// }

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ed0800;
  background-color: #ed080040;
}

.mat-form-field-subscript-wrapper {
  overflow: visible !important;
}

.mat-form-field-label-wrapper {
  overflow: visible !important;

  .mat-icon {
    cursor: pointer !important;
  }
}

.mat-form-field-label {
  overflow: visible !important;
  .mat-icon {
    pointer-events: auto !important;
  }
}

.mat-hint-text {
  font-size: 9px;
  margin-top: -0.3rem !important;
  margin-right: -0.5rem !important;
  font-weight: bold;
}

.mat-tooltip {
  background-color: #444444 !important;
  color: #f4f4f4 !important;
  border-radius: 4px !important;
  -webkit-font-smoothing: antialiased;
  text-align: justify;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  .mat-form-field-label-sch {
    font-size: 1rem !important;
    color: #00893d;
  }

  .mat-hint-icon {
    float: inline-end;
    font-size: 1.5rem !important;
    margin-top: -0.6rem;
    margin-right: -1.7rem;
  }

  .mat-hint-icon-date-picker {
    margin-right: -5.2rem !important;
  }

  .mat-form-field-outline-gap {
    margin-right: 10px;
  }
}

.mat-form-field-label-sch {
  font-size: 1rem !important;
  color: #00893d;
}

.mat-hint-icon {
  float: inline-end;
  font-size: 1.1rem !important;
  margin-top: -0.1rem;
  margin-right: -1.3rem;
}

.mat-hint-icon-select-box {
  margin-right: -2.4rem;
}

.mat-hint-icon-date-picker {
  margin-right: -3.8rem;
}

.matSelectPanelClass {
  margin-top: 1rem;
}

.mat-button-base {
  border-radius: 100px !important;
}

.mat-tab-label-content {
  text-transform: capitalize !important;
}

.mat-calendar {
  background-color: #ededed;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none;
}
